import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "../Types/Paged";
import { SubsicriptionSearchType } from "Types/SubsicriptionType";

class SubsicriptionClient {
    client: IClient;
    locale:string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }

    async SearchAsync(
        search: IPagedSearch<SubsicriptionSearchType>): Promise<ApiResponse> {
        let query = `subscriptions?limit=${search.limit}&offset=${search.offset}`
        if(search.search?.mobile){
            query+=`&mobile=${search.search?.mobile}`
        }
        if (search.search?.packageId) {
            query += '&packageId=' + search.search.packageId
        }
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

}

export default SubsicriptionClient;
